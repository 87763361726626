import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';
import { SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import Swal from 'sweetalert2';

import { Group } from '@core/interfaces/group.interface';
import { Project } from '@core/interfaces/project.interface';

import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import { CampaignsService } from '@core/services/campaigns/campaigns.service';
import { MultimediasService } from '@core/services/programs/multimedias.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';

import { FixMeLater, QRCodeElementType } from 'angularx-qrcode';
import { QRCodeErrorCorrectionLevel } from 'qrcode';
import { environment } from 'src/environments/environment.staging';

type ListType = { title: string; val: number }[];

@Component({
  selector: 'app-lines-campains',
  templateUrl: './lines-campains.component.html',
  styleUrls: ['./lines-campains.component.scss'],
})
export class LinesCampainsComponent implements OnInit {
  // =======================
  // Variables heredadas del primer snippet
  // =======================
  public validateIndicative = false;
  public typeTranslations: any = {
    'text': 'Texto',
    'multiple-choice': 'Opción Múltiple',
    'informative': 'Informativo',
  };

  // =======================
  // Variables comunes
  // =======================
  private urlBackEnd: any = environment;
  public initial_state = {
    allowEmptyString: true,
    alt: 'A custom alt attribute',
    ariaLabel: `QR Code image with the following content...`,
    colorDark: '#000000ff',
    colorLight: '#00FF0000',
    cssClass: 'center',
    elementType: 'canvas' as QRCodeElementType,
    errorCorrectionLevel: 'H' as QRCodeErrorCorrectionLevel,
    imageSrc: '',
    imageHeight: 200,
    imageWidth: 200,
    margin: 4,
    qrdata: '',
    scale: 1,
    version: undefined,
    title: 'Datos de la campaña',
    width: 300,
  };

  public allowEmptyString: boolean;
  public alt: string;
  public ariaLabel: string;
  public colorDark: string;
  public colorLight: string;
  public cssClass: string;
  public elementType: QRCodeElementType;
  public errorCorrectionLevel: QRCodeErrorCorrectionLevel;
  public imageSrc?: string;
  public imageHeight?: number;
  public imageWidth?: number;
  public margin: number;
  public qrdata: string;
  public scale: number;
  public title: string;
  public width: number;

  public qrCodeSrc!: SafeUrl;
  public selectedIndex: number;
  public marginList: ListType;
  public widthList: ListType;
  public showA11y: boolean;
  public showColors: boolean;
  public showCss: boolean;
  public showImage: boolean;
  public preview: any;
  public archivos: any = [];
  public files: File[] = [];
  public folder: string = '';
  public data_model = { ...this.initial_state };
  public groupId: string = '';
  public campaignId: string = '';
  public isEditMode: boolean = false;
  public groupName: any = '';
  public lineName: string = '';
  public fileUrl: any = '';
  public code: string = '';
  public qr_image_url: string = '';
  public listProject: Project[] = [];
  public listGroup: Group[] = [];
  public phoneNumber: string = '573135321651';
  public draftMessage: string = '';
  public draftMessageResponse: string = '';
  public sentMessage: string = '';
  public isMessageSent: boolean = true;
  public waLink: string = '';
  public questionForm: FormGroup;
  public questions: any[] = [];

  displayedColumns: string[] = [
    'position',
    'question',
    'type',
    'options',
    'actions',
  ];
  dataSource = new MatTableDataSource<any>(this.questions);

  // Para editar preguntas
  editingIndex: number | null = null;
  editedQuestion: any = {};
  isMultipleChoice: boolean = false;

  constructor(
    private fb: FormBuilder,
    public themeService: CustomizerSettingsService,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    private router: ActivatedRoute,
    private sweetAlertService: SweetAlertService,
    private _linesGroupService: LinesGroupsService,
    private campaignsService: CampaignsService,
    private multimediasService: MultimediasService
  ) {
    this.questionForm = this.fb.group({
      question: ['', Validators.required],
      type: ['', Validators.required],
      options: [''],
    });

    // Ajustes iniciales del QR
    this.selectedIndex = 0;
    this.showA11y = true;
    this.showColors = true;
    this.showCss = true;
    this.showImage = true;
    this.allowEmptyString = this.data_model.allowEmptyString;
    this.alt = this.data_model.alt;
    this.ariaLabel = this.data_model.ariaLabel;
    this.colorDark = this.data_model.colorDark;
    this.colorLight = this.data_model.colorLight;
    this.cssClass = this.data_model.cssClass;
    this.elementType = 'canvas';
    this.errorCorrectionLevel = this.data_model.errorCorrectionLevel;
    this.imageSrc = this.showImage ? this.data_model.imageSrc : undefined;
    this.imageHeight = this.showImage ? this.data_model.imageHeight : undefined;
    this.imageWidth = this.showImage ? this.data_model.imageWidth : undefined;
    this.margin = 3;
    this.qrdata = this.data_model.qrdata;
    this.scale = this.data_model.scale;
    this.title = this.data_model.title;
    this.width = window.screen.width / 4;

    this.marginList = [
      { title: '4 (Default)', val: 4 },
      { title: '0', val: 0 },
      { title: '10', val: 10 },
      { title: '25', val: 25 },
    ];

    this.widthList = [
      { title: '400', val: 400 },
      { title: '300', val: 300 },
      { title: '200', val: 200 },
      { title: '100', val: 100 },
      { title: '50', val: 50 },
      { title: '10 (Default)', val: 10 },
    ];
  }

  ngOnInit(): void {
    this.router.url.subscribe((urlSegments) => {
      this.groupId = urlSegments[urlSegments.length - 1].path;
      if (this.groupId) {
        this.loadCampaignData();
      }
    });

    this.lineName = this.router.snapshot.queryParamMap.get('line') || '';
    this.groupName = this.router.snapshot.queryParamMap.get('name');
  }

  loadCampaignData(): void {
    this.campaignsService.getCampaignByGroup(this.groupId).subscribe(
      (response) => {
        if (response.ok) {
          // Modo edición
          this.isEditMode = true;

          this.campaignId = response.data._id;

          this.code = response.data.code_campaign;
          this.qr_image_url = response.data.qr_image_url;

          this.qrdata =
            response.data.deep_link_url ??
            `${this.urlValidate()}/campaigns/redirect/${this.code}`;

          this.draftMessageResponse = response.data.prefilled_message;
          this.draftMessage = response.data.prefilled_message;
          this.questions = response.data.registry || [];

          this.dataSource.data = this.questions;
          this.sentMessage = this.draftMessage;
        } else {
          // Modo creación
          this.isEditMode = false;
          this.generateUniqueCode();
        }
      },
      () => {
        this.sweetAlertService.showErrorToast('Error al cargar la campaña');
      }
    );
  }

  generateUniqueCode(): void {
    if (!this.groupName) return;

    this.campaignsService.generateUniqueCode(this.groupId).subscribe(
      (response) => {
        if (!response.ok) {
          this.sweetAlertService.showErrorToast(
            'Error al generar el código único'
          );
        }
        this.campaignId = response.result._id;
        this.code = response.result.code_campaign;
        this.validateIndicative = response.result.validateIndicative;
        this.qrdata =
          response.result.deep_link_url ??
          `${this.urlValidate()}/campaigns/redirect/${this.code}`;
      },
      () => {
        this.sweetAlertService.showErrorToast(
          'Error al generar el código único'
        );
      }
    );
  }

  // ======================
  // Manejo de mensaje y QR
  // ======================
  onFocus() {
    this.isMessageSent = true;
  }

  onBlur() {
    if (this.draftMessage.trim()) {
      this.isMessageSent = true;
      this.sentMessage = this.draftMessageResponse
        ? this.draftMessage
        : this.draftMessage + `\n\nTu código de usuario es 👉🏻: *${this.code}*`;
    }
  }

  onInput() {
    this.isMessageSent = true;
    this.sentMessage = this.draftMessageResponse
      ? this.draftMessage
      : this.draftMessage + `\n\nTu código de usuario es 👉🏻: *${this.code}*`;
  }

  sendMessage() {
    if (this.draftMessage.trim()) {
      this.isMessageSent = true;
      this.sentMessage = this.draftMessageResponse
        ? this.draftMessage
        : this.draftMessage + `\n\nTu código de usuario es 👉🏻: *${this.code}*`;
    }
  }

  editMessage() {
    this.draftMessage = this.sentMessage;
    this.isMessageSent = true;
  }

  generateWaLink() {
    this.waLink = `https://api.whatsapp.com/send?phone=${this.phoneNumber}&text=${encodeURIComponent(
      this.sentMessage
    )}`;
    this.clipboard.copy(this.waLink);
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeSrc = url;
  }

  // ======================
  // Manejo de archivos/QR
  // ======================
  onSelect(event: any) {
    this.files = [];
    if (event.addedFiles.length > 0) {
      const file = event.addedFiles[0];
      this.files.push(file);
      this.extraerBase64(file).then((image: any) => {
        this.imageSrc = image.base;
      });
      this.archivos = file;
      const fileName = file.name;
      const folder = this.obtenerCarpetaPorFormato(fileName);
      if (folder) {
        this.folder = folder;
      } else {
        console.error('Formato de archivo no compatible:', fileName);
      }
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.qrdata);
  }

  // ======================
  // Generación / guardado de imágenes
  // ======================
  async saveAsImages(parent: FixMeLater): Promise<string | null> {
    let parentElement = null;

    if (this.elementType === 'canvas') {
      parentElement = parent.qrcElement.nativeElement
        .querySelector('canvas')
        .toDataURL('image');
    } else if (this.elementType === 'img' || this.elementType === 'url') {
      parentElement = parent.qrcElement.nativeElement.querySelector('img').src;
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.");
    }

    if (parentElement) {
      const blobData = this.convertBase64ToBlob(parentElement);
      this.fileUrl = await this.cargarArchivo(blobData);
      return null;
    }
    return null;
  }

  saveAsImage(parent: FixMeLater) {
    let parentElement = null;

    if (this.elementType === 'canvas') {
      parentElement = parent.qrcElement.nativeElement
        .querySelector('canvas')
        .toDataURL('image');
    } else if (this.elementType === 'img' || this.elementType === 'url') {
      parentElement = parent.qrcElement.nativeElement.querySelector('img').src;
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.");
    }

    if (parentElement) {
      const blobData = this.convertBase64ToBlob(parentElement);
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = this.groupName;
      link.click();
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }

  extraerBase64 = async (file: File) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => resolve({ base: reader.result });
        reader.onerror = () => resolve({ base: null });
      });
    } catch (error) {
      return { base: null };
    }
  };

  obtenerCarpetaPorFormato(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension) {
      if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        return 'img';
      } else if (['doc', 'docx', 'xlsx', 'pdf'].includes(extension)) {
        return 'documents';
      } else if (['mp3'].includes(extension)) {
        return 'audio';
      }
    }
    return 'otros';
  }

  // ======================
  // CRUD de campaña
  // ======================
  async onSubmit(parent: FixMeLater): Promise<void> {
    this.generateWaLink();
    //  Actualizar el mensaje que se envía
    this.sendMessage();
    //  Subir la imagen
    await this.saveAsImages(parent);

    // 4. Construimos el objeto de campaña
    const campaignData: any = {
      group: this.groupId,
      qr_image_url: this.imageSrc,
      prefilled_message: this.sentMessage,
      deep_link_url: this.qrdata,
      redirect_link: this.waLink,
      code_campaign: this.code,
      // Se agrega validateIndicative que viene del primer snippet
      validateIndicative: this.validateIndicative,
      // Guardar las preguntas en el campo registry
      registry: this.questions,
    };

    // Si ya existe la campaña, la actualizamos, si no, la creamos
    if (this.isEditMode) {
      if (this.fileUrl) {
        campaignData.qr_image_url = this.fileUrl;
      }
      this.updateCampaign(campaignData);
    } else {
      if (this.fileUrl) {
        campaignData.qr_image_url = this.fileUrl;
      }
      this.createCampaign(campaignData);
    }
  }

  createCampaign(campaignData: any): void {
    this.campaignsService.createCampaign(campaignData).subscribe(
      () => {
        this.sweetAlertService.showSuccessToast('Campaña creada exitosamente');
      },
      () => {
        this.sweetAlertService.showErrorToast('Error al crear la campaña');
      }
    );
  }

  updateCampaign(campaignData: any): void {
    this.campaignsService
      .updateCampaign(this.campaignId, campaignData)
      .subscribe(
        () => {
          this.sweetAlertService.showSuccessToast(
            'Campaña actualizada exitosamente'
          );
        },
        () => {
          this.sweetAlertService.showErrorToast(
            'Error al actualizar la campaña'
          );
        }
      );
  }

  async cargarArchivo(blobData: Blob): Promise<string | null> {
    const formData = new FormData();
    formData.append('carpeta', this.lineName);
    formData.append('file', blobData, 'qrcode.png');
    try {
      const response: any = await this.multimediasService
        .cargarMedia(formData)
        .toPromise();
      if (response && response.ok) {
        this.sweetAlertService.showSuccessToast(
          'Se ha realizado la carga exitosa de la media'
        );
        return response.data.fileUrl;
      } else {
        this.sweetAlertService.showErrorToast(
          'Se ha presentado un error al cargar la media'
        );
        return null;
      }
    } catch (error) {
      console.error('Error al cargar la media:', error);
      this.sweetAlertService.showErrorToast(
        'Se ha presentado un error al cargar la media'
      );
      return null;
    }
  }

  // ======================
  // CRUD de preguntas
  // ======================
  addQuestion() {
    const questionData = this.questionForm.value;
    // Si es multiple-choice, las opciones van separadas por comas
    if (questionData.type === 'multiple-choice' && questionData.options) {
      questionData.options = questionData.options
        .split(',')
        .map((option: string) => option.trim());
    } else {
      questionData.options = [];
    }

    if (this.editingIndex !== null) {
      // Estamos guardando una pregunta editada
      this.questions[this.editingIndex] = { ...questionData };
      this.editingIndex = null;
    } else {
      // Nueva pregunta
      this.questions.push({ ...questionData });
    }

    // Actualizamos la tabla
    this.dataSource.data = this.questions;
    this.questionForm.reset();

    // Guardar la nueva lista de preguntas en la campaña
    const campaignData = {
      registry: this.questions,
    };
    this.updateCampaign(campaignData);
  }

  editQuestion(question: any, index: number) {
    this.editingIndex = index;
    this.editedQuestion = { ...question };
  }

  saveQuestion(index: number) {
    const editedOptions = this.editedQuestion.options;
    if (typeof editedOptions === 'string') {
      this.editedQuestion.options = editedOptions
        .split(',')
        .map((option: string) => option.trim());
    }
    this.questions[index] = { ...this.editedQuestion };
    this.dataSource.data = this.questions;
    this.cancelEdit();

    const campaignData = {
      registry: this.questions,
    };
    this.updateCampaign(campaignData);
  }

  cancelEdit() {
    this.editingIndex = null;
    this.editedQuestion = {};
  }

  onTypeChange(event: any) {
    this.isMultipleChoice = event.value === 'multiple-choice';
  }

  deleteQuestion(question: any) {
    // Si quieres confirmar con un sweetalert, lo agregas; acá, lo hacemos directo:
    this.questions = this.questions.filter((q) => q !== question);
    this.dataSource.data = this.questions;
    const campaignData = {
      registry: this.questions,
    };
    this.updateCampaign(campaignData);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    this.dataSource.data = this.questions;
    const campaignData = {
      registry: this.questions,
    };
    this.updateCampaign(campaignData);
  }

  // ======================
  // Helpers
  // ======================
  private urlValidate(): string {
    const url = window.location.href;
    const pattern = [/test\.conhector\.fundak\.io/i, /conhector\.me/i];

    // production
    if (
      pattern.some((patron) => patron.test(this.urlBackEnd.production)) &&
      url.includes(new URL(this.urlBackEnd.production).hostname)
    ) {
      return this.urlBackEnd.production;
    }

    // base_url
    if (
      pattern.some((patron) => patron.test(this.urlBackEnd.base_url)) &&
      url.includes(new URL(this.urlBackEnd.base_url).hostname)
    ) {
      return this.urlBackEnd.base_url;
    }

    return '';
  }
}

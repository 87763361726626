import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

import Swal from 'sweetalert2';

import { MultimediasService } from '@core/services/programs/multimedias.service';
import { FilterService } from '@core/services/filters/filter.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';
import { ProgramsService } from '@core/services/programs/programs.service';
import { Multimedias } from '@core/interfaces/multimedia.interface';
import { Multimedia } from '@core/interfaces/Programs.interface';

@Component({
  selector: 'app-dialog-media',
  templateUrl: './dialog-media.component.html',
  styleUrls: ['./dialog-media.component.scss'],
})
export class DialogMediaComponent implements OnInit, OnDestroy {
  // Variables de paginación
  public pageIndex: number = 1;
  public pageSize: number = 10;
  public totalItems: number = 0;

  displayedColumns: string[] = ['name', 'image', 'type', 'action'];

  public listMultimedias: Multimedia[] = [];
  dataSource = new MatTableDataSource<Multimedia>(this.listMultimedias);

  public searchValue: string = '';
  private filterSubscription: Subscription | undefined;

  public archivos: File | null = null;
  public files: File[] = [];
  public multimediaCtrl = new FormControl();
  public folder: string = '';
  public step!: FormGroup;
  public fileData: { name: string; base: any } = { name: '', base: '' };
  public idMultimedia: string = '';
  private _isDisabled: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _mediasService: MultimediasService,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogMediaComponent>,
    private sweetAlertService: SweetAlertService,
    private _programService: ProgramsService
  ) {}

  ngOnInit(): void {
    // Inicializar formulario
    this.step = this.fb.group({
      numberStep: [''],
      interaction: ['Informativo', Validators.required],
      multimedia: [''],
      description: [''],
    });

    // Cargamos la lista inicial sin filtros
    this.getListMultimedias();

    // Suscripción a los cambios de filtros
    this.filterSubscription = this.filterService.filter$.subscribe(
      (filters) => {
        this.searchValue = filters.searchValue;
        // Cuando haga una nueva búsqueda, reseteamos a la página 0:
        this.pageIndex = 0;
        // y cargamos
        this.getListMultimedias(this.searchValue);
      }
    );

    // Si viene data para edición...
    if (this.data && this.data.step !== undefined) {
      this.step.patchValue({
        numberStep: this.data.step.numberStep,
        interaction: this.data.step.interaction,
        description: this.data.step.description,
        multimedia: this.data.step.multimedia,
      });
    }
  }

  ngOnDestroy(): void {
    this.step.reset();
    this.multimediaCtrl.reset();
    this.listMultimedias = [];
    this.files = [];
    // Destruir la suscripción
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  /**
   * Llama al servicio para obtener la lista de multimedias paginada.
   * @param searchValue Valor de búsqueda opcional.
   */
  getListMultimedias(searchValue: string = ''): void {
    // Calculamos offset (cuántos registros saltar) en base a pageIndex y pageSize
    const page = this.pageIndex;

    this._mediasService
      .getListMedia(this.pageSize, page, searchValue)
      .subscribe(
        (resp: Multimedias) => {
          if (resp.ok && Array.isArray(resp.data)) {
            this.listMultimedias = resp.data;
            this.totalItems = resp.total || 0;
            this.dataSource.data = this.listMultimedias;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de tipo PageEvent (MatPaginator).
   */
  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    // Volvemos a cargar la data
    this.getListMultimedias(this.searchValue);
  }

  async eventStep() {
    if (this.data && this.data.step !== undefined) {
      await this.editStepById(this.data.idProgram);
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.step.value);
    }
  }

  editStepById(idProgram: string) {
    this._programService.updateStep(idProgram, this.step.value).subscribe(
      (resp: any) => {
        if (resp.ok) {
          this.handleMediaUpload();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // Ejemplo para registrar link de video
  registerMedia() {
    const newMedia = {
      name: this.step.value.title,
      fileUrl: this.step.value.url,
    };

    this._mediasService.guardarLinkVideo(newMedia).subscribe(
      (res: any) => {
        this.handleMediaUpload();
        this.step.value.media = res;
        this.step.value.fileUrl = '';
        this.step.value.title = '';
      },
      (err) => {
        console.error('Error al registrar la media:', err);
        let errorMessage = 'Se ha presentado un error al registrar la media';
        if (err?.error?.message) {
          errorMessage = err.error.message;
        }
        Swal.fire('Registro de medios', errorMessage, 'error');
      }
    );
  }

  // Subir archivo
  cargarArchivo() {
    const formData = new FormData();
    formData.append('carpeta', this.step.value.title || this.archivos?.name);
    if (this.archivos instanceof File) {
      formData.append('file', this.archivos);
    }

    Swal.fire({
      title: 'Subiendo archivo...',
      text: 'Por favor, espere unos segundos',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
        this._mediasService.cargarMedia(formData).subscribe(
          (res: Multimedias) => {
            if (res.ok && !Array.isArray(res.data)) {
              this.step.value.multimedia = {
                url: res.data?.url,
                type: res.data?.type,
              };
              this.sweetAlertService.showSuccessToast(
                'Se ha cargado el archivo correctamente'
              );
            }
          },
          (err) => {
            console.error('Error al cargar la media:', err);
            let errorMessage = 'Se ha presentado un error al cargar la media';
            if (err?.error?.message) {
              errorMessage = err.error.message;
            }
            Swal.fire('Carga de medios', errorMessage, 'error');
          }
        );
      },
    });
  }

  handleMediaUpload(): void {
    return this.sweetAlertService.showSuccessToast(
      'Se ha añadido la multimedia al paso correctamente'
    );
  }

  onSelect(event: any): void {
    if (event.addedFiles && event.addedFiles.length > 0) {
      this.files = [...event.addedFiles];

      this.archivos = this.files[0];

      this.step.get('files')?.setValue(this.files);

      this.step.get('files')?.updateValueAndValidity();

      this.step.get('url')?.disable();
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
    this.step.get('files')?.setValue(this.files);
    if (this.files.length === 0) {
      this.step.get('url')?.enable();
    }
  }

  checkFileUrl(event: any) {
    this.step.patchValue({
      multimedia: {
        url: event.data,
        type: 'enlace',
      },
    });

    if (this.step.get('url')?.value) {
      this.files = [];
    }
  }

  selectMedia(event: any) {
    this.step.value.multimedia = {
      url: event.value?.url,
      type: event.value?.type,
    };
  }

  saveMedia() {
    if (this.files.length > 0) {
      this.cargarArchivo();
    } else if (this.step.get('fileUrl')?.value) {
      this.registerMedia();
    }
  }

  close() {
    this.dialogRef.close();
  }
}

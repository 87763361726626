import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { User } from '@core/interfaces/User.interface';
import { ToggleService } from 'src/app/core/services/toggle.service';
import { CustomizerSettingsService } from 'src/app/core/services/customizer-settings.service';
import { Router } from '@angular/router';
import { ProjectsService } from '@core/services/configurations/projects.service';
import { Project, Projects } from '@core/interfaces/project.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public userInfo: User = {};
  public listProjects: Project[] = [];
  public projectSelect: Project | any = '';
  public projectCache = '';

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  isToggled = false;

  constructor(
    public router: Router,
    private datePipe: DatePipe,
    private toggleService: ToggleService,
    private projectsService: ProjectsService,
    private authStateService: AuthStateService,
    public sweetAlertService: SweetAlertService,
    public themeService: CustomizerSettingsService
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });

    this.authStateService.project$.subscribe((project) => {
      this.projectCache = project || '';
      this.filterProject();
    });
  }

  ngOnInit(): void {
    this.getProjects();
    this.authStateService.project$.subscribe((project) => {
      this.projectCache = project || '';
      this.filterProject();
    });
  }

  ngOnChanges(): void {
    this.getProjects();
    this.authStateService.project$.subscribe((project) => {
      this.projectCache = project || '';
      this.filterProject();
    });
  }
  closeDropdown() {
    const dropdownInput = document.getElementById(
      'dropdown'
    ) as HTMLInputElement;
    if (dropdownInput) {
      dropdownInput.checked = false;
    }
  }
  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggle() {
    this.toggleService.toggle();
  }

  currentDate: Date = new Date();
  formattedDate: any = this.datePipe.transform(
    this.currentDate,
    'dd MMMM yyyy',
    'es'
  );

  private filterProject() {
    // Asegurarse de que se encuentra el proyecto seleccionado
    const selectedProject = this.listProjects.find(
      (project) => project.name === this.projectCache
    );
    if (selectedProject) {
      this.projectSelect = selectedProject;
    }
  }

  getProjects() {
    this.projectsService.getProjectsList().subscribe(
      (next: Projects) => {
        if (next.ok) {
          if (Array.isArray(next.data)) {
            this.listProjects = next.data;
            this.filterProject(); // Después de obtener los proyectos, se filtra el proyecto seleccionado.
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateProject(event: any) {
    this.projectSelect = event;
    let text_message = '¿Estás seguro de cambiar de proyecto?';
    this.sweetAlertService
      .showConfirmActionAlert(
        'Cambiar de proyecto',
        text_message,
        'Haz cambio correctamente de proyecto',
        'Actualizar'
      )
      .then((result) => {
        if (result?.isConfirmed) {
          this.authStateService.setProject(event.name || null);
          window.location.reload();
        }
      });
  }
}

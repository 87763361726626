import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  constructor() {}

  // Alerta básica
  showBasicAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'info',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#262D48',
      width: '30rem',
    });
  }

  // Alerta de confirmación
  showConfirmAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      confirmButtonColor: '#262D48',
      cancelButtonColor: '#FF6D39',
      width: '30rem',
    });
  }

  // Alerta de éxito
  showSuccessAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'success',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#262D48',
      width: '30rem',
    });
  }

  // Toast de éxito
  showSuccessToast(title: string): void {
    Swal.fire({
      title,
      icon: 'success',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      background: '#ffffff',
      color: '#262D48',
      customClass: {
        container: 'toast-container',
      },
    });
  }

  // Toast de error
  showErrorToast(title: string): void {
    Swal.fire({
      title,
      icon: 'error',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      background: '#ffffff',
      color: '#262D48',
      customClass: {
        container: 'toast-container',
      },
    });
  }

  // Toast de advertencia
  showWarningToast(title: string): void {
    Swal.fire({
      title,
      icon: 'warning',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      background: '#ffffff',
      color: '#262D48',
      customClass: {
        container: 'toast-container',
      },
    });
  }

  // Toast de información
  showInfoToast(title: string): void {
    Swal.fire({
      title,
      icon: 'info',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      background: '#ffffff',
      color: '#262D48',
      customClass: {
        container: 'toast-container',
      },
    });
  }

  // Toast de confirmación
  showConfirmToast(title: string, callback: () => void): void {
    Swal.fire({
      title,
      icon: 'warning',
      toast: true,
      position: 'top-end',
      showCancelButton: true,
      confirmButtonColor: '#262D48',
      cancelButtonColor: '#FF6D39',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      background: '#ffffff',
      color: '#262D48',
      customClass: {
        container: 'toast-container',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      }
    });
  }

  // Alerta de error
  showErrorAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'error',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#262D48',
      width: '30rem',
    });
  }

  // Alerta de advertencia
  showWarningAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#262D48',
      width: '30rem',
    });
  }

  // Alerta personalizada
  showCustomAlert(options: SweetAlertOptions): Promise<any> {
    return Swal.fire({
      ...options,
      confirmButtonColor: '#262D48',
      cancelButtonColor: '#FF6D39',
      width: '30rem',
    });
  }

  // Alerta de carga
  showLoadingAlert(title: string, text: string): void {
    Swal.fire({
      title,
      text,
      allowOutsideClick: false,
      willOpen: () => Swal.showLoading(),
      width: '30rem',
    });
  }

  // Cerrar alerta de carga
  closeLoadingAlert(): void {
    Swal.close();
  }

  // Alerta con temporizador
  showTimedAlert(title: string, text: string, timer: number): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'info',
      timer,
      timerProgressBar: true,
      showCloseButton: true,
      showConfirmButton: false,
      width: '30rem',
    });
  }

  // Alerta de confirmación con acción
  showConfirmActionAlert(
    title: string,
    description: string,
    text: string,
    buttonConfirm: string
  ): Promise<any> {
    return Swal.fire({
      title,
      text: description,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#262D48',
      cancelButtonColor: '#FF6D39',
      confirmButtonText: buttonConfirm,
      cancelButtonText: 'Cancelar',
      width: '30rem',
    }).then((result) => {
      if (result.isConfirmed) {
        this.showSuccessToast(text);
        return { isConfirmed: true };
      } else {
        return Promise.resolve(null);
      }
    });
  }

  // Alerta de confirmación con entrada
  showConfirmInputAlert(title: string, inputPlaceholder: string): Promise<any> {
    return Swal.fire({
      title,
      input: 'text',
      inputPlaceholder: inputPlaceholder,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#262D48',
      cancelButtonColor: '#FF6D39',
      width: '30rem',
    });
  }

  showInfoAlert(title: string, text: string): Promise<any> {
    return this.showCustomAlert({
      title,
      text,
      icon: 'info',
      confirmButtonText: 'Aceptar',
    });
  }

  // Alerta de selección
  showSelectAlert(title: string, text: string, options: any[]): Promise<any> {
    return Swal.fire({
      title,
      text,
      input: 'select',
      inputOptions: options,
      inputPlaceholder: 'Selecciona una opción',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#262D48',
      cancelButtonColor: '#FF6D39',
      width: '30rem',
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListsLineComponent } from './pages/lists-line/lists-line.component';
import { CreateEditLineComponent } from './pages/create-edit-line/create-edit-line.component';
import { LinesCampainsComponent } from './pages/lines-campains/lines-campains.component';

const routes: Routes = [
  {
    path: 'lists',
    component: ListsLineComponent,
    data: {
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: '',
      },
      title: 'Listado de Líneas',
      url: '/lines/lists',
    },
  },
  {
    path: 'new-line',
    component: CreateEditLineComponent,
    data: {
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: '/',
      },
      title: 'Nueva Línea',
      url: '/lines/new-line',
      icon: '../../../assets/img/ConHector/Personaje_acciones/ConHector_Evil.png',
      parents: [
        {
          title: 'Listado de Líneas',
          url: '/lines/lists',
        },
      ],
    },
  },
  {
    path: 'edit-line/:id',
    component: CreateEditLineComponent,
    data: {
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: '/',
      },
      title: 'Editar Línea',
      url: '/lines/edit-line/:id',
      parents: [
        {
          title: 'Listado de Líneas',
          url: '/lines/lists',
        },
      ],
    },
  },
  {
    path: 'line-campaign/:id',
    component: LinesCampainsComponent,
    data: {
      firstBreadcrumb: {
        title: 'Con-Héctor',
        icon: '../../../assets/img/ConHector/Personaje_acciones/icon.png',
        url: '/',
      },
      title: 'Campaña',
      url: 'lines/line-campaign/:id',
      parents: [
        {
          title: 'Listado de Líneas',
          url: '/lines/lists',
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LinesGroupsRoutingModule {}

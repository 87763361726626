import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Group } from '@core/interfaces/group.interface';
import { Line, Lines } from '@core/interfaces/lines.interface';
import {
  Indicative,
  Inidicatives,
} from '@core/interfaces/indicatives.interface';

import { SweetAlertService } from '@core/services/sweet-alert.service';
import { SpinnerService } from '@core/services/shared/spinner.service';
import { CampaignsService } from '@core/services/campaigns/campaigns.service';
import { IndicativesService } from '@core/services/linesGroups/indicatives.service';
import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';

import { DialogViewCampaignComponent } from '../dialog-view-campaign/dialog-view-campaign.component';
@Component({
  selector: 'app-create-edit-line',
  templateUrl: './create-edit-line.component.html',
})
export class CreateEditLineComponent implements OnInit {
  displayedColumns: string[] = ['name', 'description', 'action'];
  title = 'Nueva Línea';
  listGroup: Group[] = [];
  listIndicatives: Indicative[] = [];
  public lineImg: string = 'assets/img/icons/2_pajaro.png';
  listRespGroup: Group[] = [];
  dataSource = new MatTableDataSource<Group>(this.listGroup);
  idLines = '';
  linesForm: FormGroup;
  lineName = '';
  questions: any[] = [];
  questionForm: FormGroup;

  dataSelect: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
    private campaignsService: CampaignsService,
    private sweetAlertService: SweetAlertService,
    private themeService: CustomizerSettingsService,
    private _linesGroupService: LinesGroupsService,
    private indicativesService: IndicativesService
  ) {
    this.linesForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      indicative: [''],
      image: [''],
      description: [''],
      groups: [[]],
    });
    this.linesForm.get('indicative')!.disable();
    this.questionForm = this.fb.group({
      question: ['', Validators.required],
      type: ['', Validators.required],
      options: [''],
    });
  }

  ngOnInit(): void {
    this.idLines = this.activatedRoute.snapshot.paramMap.get('id') || '';
    if (this.idLines) {
      this.getInfoLines();
    }
    this.getListIndicatives();
  }

  private getListIndicatives(): void {
    this.indicativesService.getIndicatives().subscribe(
      (res: Inidicatives) => {
        if (res.ok) {
          if (Array.isArray(res.data)) {
            this.listIndicatives = res.data;
          }
        }
      },
      (err) => console.error(err)
    );
  }

  private getInfoLines(): void {
    this.spinnerService.setLoading(true);
    this._linesGroupService.getLinesById(this.idLines).subscribe(
      (res: Lines) => {
        if (res.ok) {
          if (!Array.isArray(res.data)) {
            this.linesForm.patchValue({
              name: res.data.name,
              indicative: res.data.indicative,
              description: res.data.description,
              image: res.data?.image ?? 'assets/img/icons/2_pajaro.png',
            });
            this.dataSelect = res.data.name;
            this.lineImg = res.data?.image ?? 'assets/img/icons/2_pajaro.png';
            this.questions = res.data.registerLine || [];
            this.lineName = res.data.name;
            this.listGroup = res.data.groups || [];
            this.dataSource = new MatTableDataSource(this.listGroup);
          }
        }
      },
      (err) => console.error(err),
      () => this.spinnerService.setLoading(false)
    );
  }

  addNewGroupRow(): void {
    if (this.listGroup.some((group) => group.isEditing)) {
      this.sweetAlertService.showWarningAlert(
        'Complete la edición actual antes de agregar un nuevo grupo',
        ''
      );
      return;
    }

    const newGroup: Group = {
      _id: '',
      name: '',
      description: '',
      idLines: this.idLines,
      isEditing: true,
    };
    this.listGroup.push(newGroup);
    this.dataSource.data = this.listGroup;
    this.dataSource = new MatTableDataSource<Group>(this.listGroup);
  }

  saveGroup(group: Group): void {
    console.log(group);
    if (this.idLines) {
      const errors = this.validateGroup(group);
      if (errors.length > 0) {
        this.sweetAlertService.showWarningAlert('Error', errors.join('<br>'));
        return;
      }

      const saveGroup: Group = {
        name: group.name || '',
        description: group.description || '',
      };

      if (group._id) {
        this._linesGroupService.updateGroupById(saveGroup, group._id).subscribe(
          (resp) => {
            if (resp.ok) {
              this.sweetAlertService.showSuccessToast(
                'Grupo actualizado con éxito'
              );
              group.isEditing = false;
            }
          },
          (err) => {
            this.sweetAlertService.showErrorAlert('Error', err.error.msg);
          }
        );
      } else {
        this._linesGroupService.createGroup(group).subscribe(
          (resp) => {
            if (resp.ok) {
              this.sweetAlertService.showSuccessToast('Grupo creado con éxito');
              group.isEditing = false;
              if (Array.isArray(resp.data)) {
                const newGroup = resp.data[0];
                const indexGroup: any = this.listGroup.findIndex(
                  (group: any) => group.name === newGroup.name
                );
                if (indexGroup) {
                  this.listGroup[indexGroup]._id = newGroup._id;
                }
              }
            }
          },
          (err) => {
            this.sweetAlertService.showErrorAlert('Error', err.error.msg);
          }
        );
      }
    }
  }

  private validateGroup(group: Group): string[] {
    const errors: string[] = [];
    if (!group.name) {
      errors.push('El nombre es requerido.');
    } else {
      if (group.name.length < 2) {
        errors.push('El nombre debe tener al menos 2 caracteres.');
      }
      if (group.name.length > 50) {
        errors.push('El nombre no puede tener más de 50 caracteres.');
      }
    }
    return errors;
  }

  cancelGroup(rowGroups: any) {
    if (rowGroups[0].id) {
      this.listGroup = this.listGroup.map((g) =>
        g._id === rowGroups[1]._id ? { ...rowGroups[1], isEditing: false } : g
      );
    } else {
      this.listGroup = this.listGroup.filter((g) => g !== rowGroups[0]);
    }

    this.dataSource = new MatTableDataSource(this.listGroup);
  }

  selectIndicative(event: any) {
    this.linesForm.patchValue({
      indicative: event.indicative,
      name: event.name,
      image: event.image,
    });
    this.lineImg = event.image;
  }

  async saveLine() {
    if (this.linesForm.valid) {
      const formData = this.linesForm.getRawValue();
      const lineData: Line = {
        name: formData.name,
        indicative: formData.indicative,
        description: formData.description,
        registerLine: this.questions,
        image: this.lineImg,
        groups: [],
      };

      try {
        if (this.idLines) {
          this.editLines();
        } else {
          const resp = await this._linesGroupService
            .registerLine(lineData, this.listGroup)
            .toPromise();

          if (resp?.ok && !Array.isArray(resp?.data)) {
            this.idLines = resp.data?._id || '';
            this.sweetAlertService.showSuccessToast(
              'Línea creada exitosamente'
            );
            this.router.navigate(['lines/lists']);
          }
        }
      } catch (error: any) {
        const statusError = error.statusText || '500';
        const errorMessage =
          error.error.message ||
          'Ocurrió un error en la solicitud de las lineas';
        this.sweetAlertService.showErrorAlert(statusError, errorMessage);
      }
    } else {
      this.sweetAlertService.showWarningAlert(
        'Error',
        'Complete los campos requeridos.'
      );
    }
  }

  registerNewGroup(group: Group): void {
    if (this.listGroup.some((g) => g.isEditing)) {
      this.sweetAlertService.showWarningAlert(
        'Complete la edición actual antes de agregar un nuevo grupo',
        ''
      );
      return;
    }

    const newGroup: Group = {
      _id: '',
      name: group.name || '',
      description: group.description || '',
    };
    this._linesGroupService.createGroup(newGroup).subscribe(
      (resp) => {
        if (resp.ok) {
          this.sweetAlertService.showSuccessAlert('Grupo creado con éxito', '');
          if (!Array.isArray(resp.data)) {
            this.listGroup.push(resp.data);
            this.dataSource = new MatTableDataSource(this.listGroup);
            let dataGroups = this.linesForm.get('groups')?.value;

            dataGroups.push(resp.data._id);
            this.editLines();
          }
        }
      },
      (err) => {
        this.sweetAlertService.showErrorAlert('Error', err.error.msg);
      }
    );
  }

  async editLines() {
    await this._linesGroupService
      .updateLineById(this.linesForm.value, this.idLines, this.listGroup)
      .subscribe(
        (resp) => {
          if (resp.ok) {
            this.sweetAlertService.showSuccessToast(
              'Línea actualizada con éxito'
            );
            this.router.navigate(['lines/lists']);
          }
        },
        (err) => this.sweetAlertService.showErrorAlert('Error', err.error.msg)
      );
  }

  async deleteGroup(group: Group): Promise<void> {
    const confirmDelete = await this.sweetAlertService.showConfirmAlert(
      '¿Estás seguro?',
      `Vas a eliminar el grupo ${group.name}`
    );

    if (!confirmDelete) return;

    const otherGroups = this.listGroup.filter((g) => g._id !== group._id);

    if (!otherGroups.length) {
      return this.sweetAlertService.showWarningAlert(
        'No hay otros grupos',
        'No se puede eliminar el único grupo restante.'
      );
    }

    const groupOptions = otherGroups.reduce(
      (options, g) => ({
        ...options,
        [g._id!]: g.name,
      }),
      {} as { [key: string]: string }
    );

    const { value: targetGroupId } =
      await this.sweetAlertService.showCustomAlert({
        title: 'Seleccionar grupo',
        text: 'Selecciona un grupo para migrar la información antes de eliminar el grupo seleccionado',
        input: 'select',
        inputOptions: groupOptions,
        inputPlaceholder: 'Selecciona un grupo',
        showCancelButton: true,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        inputValidator: (value) =>
          !value ? 'Debes seleccionar un grupo' : null,
      });

    if (!targetGroupId) {
      return this.sweetAlertService.showWarningAlert(
        'No se seleccionó grupo',
        'No se ha seleccionado ningún grupo para migrar la información'
      );
    }

    const targetGroup = otherGroups.find((g) => g._id === targetGroupId);

    const result = await this.sweetAlertService.showConfirmAlert(
      '¿Seguro de realizar el cambio?',
      `Se migrará la información al grupo ${targetGroup?.name} y se eliminará el grupo ${group.name}`
    );

    if (result.isConfirmed && group._id && targetGroup?._id) {
      this._linesGroupService
        .deleteGroupById(group._id, targetGroup._id, this.idLines)
        .subscribe(
          (resp) => {
            if (resp.ok) {
              this.sweetAlertService.showSuccessAlert(
                'Grupo eliminado',
                `Se migraron ${resp.data} participantes al grupo ${targetGroup.name}`
              );
            }
            this.listGroup = otherGroups;
            this.dataSource = new MatTableDataSource(this.listGroup);
          },
          (err) => this.sweetAlertService.showErrorAlert('Error', err.error.msg)
        );
    } else {
      this.sweetAlertService.showInfoAlert(
        'Proceso cancelado',
        'Se ha cancelado el proceso'
      );
    }
  }

  editCampain(data: any): void {
    this.campaignsService.getCampaignByGroup(data.id).subscribe(
      (response) => {
        if (response.ok && response.data) {
          const dialogRef = this.dialog.open(DialogViewCampaignComponent, {
            width: '60vw',
            data: response.data,
          });

          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.router.navigate(['lines/line-campaign', data.id], {
                queryParams: { name: data.name, line: this.lineName },
              });
            }
          });
        } else {
          this.router.navigate(['lines/line-campaign', data.id], {
            queryParams: { name: data.name, line: this.lineName },
          });
        }
      },
      (error) => {
        this.sweetAlertService.showErrorAlert('Error', error.error.msg);
        this.router.navigate(['lines/line-campaign', data.id], {
          queryParams: { name: data.name, line: this.lineName },
        });
      }
    );
  }

  updateQuestion(questions: any): void {
    this.questions = questions;
    if (this.idLines) {
      const lineData: any = {
        registerLine: this.questions,
      };
      this._linesGroupService
        .updateLineById(lineData, this.idLines, this.listGroup)
        .subscribe(
          (resp) => {
            if (resp.ok) {
              this.sweetAlertService.showSuccessToast(
                'La pregunta ha sido guardada correctamente'
              );
            }
          },
          (err) => this.sweetAlertService.showErrorAlert('Error', err.error.msg)
        );
    }
  }

  onPageChange(event: any): void {}

  toggleTheme(): void {
    this.themeService.toggleTheme();
  }

  toggleRTLEnabledTheme(): void {
    this.themeService.toggleRTLEnabledTheme();
  }
}
